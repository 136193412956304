.vis {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
}

#notificationText {
  color: white;
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background-color: black;
}

.layout-column {
  flex-direction: column;
}

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: flex;
}

.layout-align-start-stretch, .layout-align-center-stretch, .layout-align-end-stretch, .layout-align-space-between-stretch, .layout-align-space-around-stretch {
  align-items: stretch;
  align-content: stretch;
}

.layout-align-start, .layout-align-start-start, .layout-align-start-center, .layout-align-start-end, .layout-align-start-stretch {
  justify-content: flex-start;
}

#key-slider .c-2-color {
  background-color: blue;
  background-image: linear-gradient(to left, hsla(120, 80%, 50%, 1), hsla(60, 80%, 50%, 1), hsla(0, 80%, 50%, 1));
}

#key-slider .c-1-color {
  background: hsl(0, 80%, 50%);
  background-image: linear-gradient(to left, hsla(0, 80%, 50%, 1), hsla(0, 80%, 0%, 1));
}

#key-slider .c-3-color {
  background-image: linear-gradient(to right, hsla(120, 80%, 50%, 1), hsla(240, 80%, 50%, 1));
}
