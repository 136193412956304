.help-button {
  & span {
    display: flex;
    align-items: center;
    font-size: 28px;
    background: white;
    opacity: 1;
    border-radius: 100%;
  }

  & svg {
    color: black;
    opacity: 0.85;
  }
}

@primary-color: #3c61b4;