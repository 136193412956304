.recommendation-detail-container {
  width: 100%;
  overflow-y: auto;
}

.recommendation-detail-card1-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 16px;
  align-items: baseline;
}

.recommendation-detail-card2 {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 18px;
  align-items: baseline;
}

.recommendation-detail-card2-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.recommendation-detail-card2-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
}

.recommendation-detail-card2-deficit {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 16px;
}

.recommendation-detail-card2-vwc {
  display: flex;
  width: 50%;
}

.recommendation-detail-actions {
  margin-top: 10px;
  margin-bottom: 20px;
}

.highcharts-axis-labels span { 
  word-break: break-all !important;
  width: 45px !important;
  white-space: normal !important;
}
