.home-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}

.home-container .ant-card {
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  width: 100%;
}

.home-container .ant-card-head,
.home-container .ant-card-body {
  padding-left: 10px;
  padding-right: 10px;
}

.home-container .ant-tag {
  margin-right: 0px;
  height: 30px;
  font-size: 16px;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.home-container .ant-card-head-wrapper {
  column-gap: 10px;
}

.home-container .ant-card-head-title,
.home-container .ant-card-extra,
.home-container .ant-card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.home-container .ant-card-head {
  border-bottom: 1px solid #ddd;
  border-radius: 5px 5px 0px 0px;
  background-color: #fafafa;
}

.home-container .ant-card-head-title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recommendation-list-item-content {
  margin-bottom: 15px;
}

.recommendation-list-item-content .recommendation-description,
.recommendation-more-button button {
  font-size: 16px;
}

.home-container .recommendation-list, .ant-card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.home-container .ant-card-actions {
  border-top: none;
  margin-left: 10px;
  margin-right: 10px;
}

.home-container .ant-card-actions > li {
  margin: 0px;
  margin-bottom: 10px;
}

.recommendation-list-item-footer,
.action-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 15px;
}

.action-container-primary {
  flex: 65;
}

.action-container-secondary {
  flex: 35;
}

.am-wingblank.am-wingblank-lg {
  margin-left: 10px;
  margin-right: 10px;
}

.recommended-scrollable-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.irrigation-equation-container .equation-message {
  padding: 10px;
}

.vwc-chart-icon {
  height: 50px;
  width: 50px;
  border-radius: 20px;
  cursor: pointer;
  touch-action: none;
}

#draggableIcon {
  width: 50px;
  overflow: visible !important;
  backface-visibility: hidden;
  touch-action: none;
}

.recommendation-more-button>button {
  padding: 0px;
  border: none;
}

.preference-model-sections-header{
  font-size: large;
  font-weight: 700;
}

.preference-modal-settings-icon {
  padding-right: 10px;
  padding-top: 3px;
}

.preference-button:hover,
.preference-button:focus,
.preference-button:active {
  color: black;
  border-color: white;
  box-shadow: none;
}

.column-gap-10 {
  column-gap: 10px;
}

@media (min-width: 900px) {

  .home-container {
    grid-template-columns:  repeat(auto-fit, 350px);
  }

  .page-container {
    width: 900px;
    height: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
    background-color: white;
    padding: 15px;
  }

}
