.map-settings-container .ant-typography {
  margin-bottom: 20px;
}

.map-settings-row {
  margin-left: 20px;
}

.map-settings-container span {
  font-size: 16px;
}
