.warning-container {
    display: flex;
  }
  
.warning-icon {
    background: white;
    color: red;
    font-size: 60px;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: 25px;
}

.warning-title {
    font-size: 25px;
    color: red;
}

.warning-card {
    box-shadow: 0px 1px 1px rgb(205, 9, 9);
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid red;
}