.list-sensor-detail-page {
  & .page-header {
    margin-left: 0;
  }

  & .sensor-detail-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  & .sensor-detail-header-container {
    margin: 20px 0;

    & .sensor-detail-header {
      width: 100%;
    }
  }

  & .title-and-alarm-container,
  & .icontitle-container .ant-typography:first-child {
    display: none;
  }

  & .list-item-header {
    display: inline-block;
  }
}

@primary-color: #3c61b4;