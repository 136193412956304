@import '../../../app/colors';

.flexbox {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.select-weekdays {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
}

.select-weekdays .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 11vw;
  height: 11vw;
  border-radius: 100%;
  color: white;
  max-width: 55px;
  max-height: 55px;
  background-color: #ddd;
  color: #555;
}

.select-weekdays .item.selected {
  background-color: @primary;
  color: white;
}

@primary-color: #3c61b4;