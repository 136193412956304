@import '../components/AppTemplate';

@create-button-size: 55px;
@create-button-margin: 15px;

.schedule-scrollable-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.schedule-calendar-container {
  height: 100%;
}

.schedule-page-container {
  display: 'flex';
  flex-direction: 'column';
  padding: 10px;
}

.schedule-share-container {
  justify-content: space-between;
}

.bottom-button {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.layout-mobile .ant-btn-primary.circle-create-btn {
  position: absolute;
  bottom: calc(@header-height + @create-button-margin + env(safe-area-inset-bottom) + env(safe-area-inset-top));
  right: calc(@create-button-margin + env(safe-area-inset-right));
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  width: @create-button-size;
  height: @create-button-size;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3)
}

.schedule-share-form .ant-picker {
  width: 100%;
}

.schedule-share-form .ant-form-item {
  margin-bottom: 10px;
}

.schedule-share-form .ant-form-item-label {
  height: 35px;
}

.schedule-share-form .ant-tag {
  border-radius: 10px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 13px;
}

.ant-form-item .ant-select,
.ant-form-item .ant-input,
.ant-form-item .ant-picker {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 5px;
}

.schedule-content-container {
  margin-top: 10px;
}

.schedule-new-cal-block {
  flex-grow: 1;
  align-self: center;
}

.duration-slider-wrapper {
  align-items: center;
  justify-content: center;
}

.duration-slider {
  height: 30px;
  margin-top: 30px;
}

.duration-slider-container {
  display: flex;
  min-height: 240px;
  padding: 20px;
}

.schedule-block-times {
  margin-left: 0px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.ant-btn.schedule-block-item {
  margin: 0px;
  text-align: left;
  font-size: 14px;
  white-space: normal;
  height: auto;
  padding: 8px 15px;
  border-radius: 10px;
}

.schedule-select {
  padding: 0px 6px;
  margin-bottom: 4px;
}

.no-schedule {
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.schedule-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
}

.loader-common {
  font-size: medium;
  padding-left: 10px;
}

.ant-select-multiple .ant-select-selector::after {
  display: none;
}

.block-select-tag {
  margin: 2px;
}

.layout-mobile {
  .date-picker-container {
    row-gap: 10px;
  }

  .block-schedule-header {
    align-items: center;
  }

  .block-schedule-header .block-title {
    margin-left: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: unset;
  }

  ;

  .schedule-block-times.empty {
    color: #888;
    font-size: 14px;
  }

  .dhx_event_resize {
    height: 0px;
  }

  .dhx_cal_editor {
    background-color: transparent;
    display: none;
  }

  .repeat-end-form-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }

  .repeat-end-form-items>*,
  .repeat-end-form-items .ant-select-selector {
    height: 40px;
    line-height: 40px;
    text-align: center;
    align-items: center;
  }

  .schedule-select {
    padding: 0px;
    margin: 10px 0px;
    min-height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .schedule-select .ant-select {
    border-radius: 5px;
  }
}

.water-usage-share {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.schedule-options-share-modal {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.schedule-date-range {
  width: 35%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.schedule-font-style {
  font-size: 16px;
  font-weight: bold;
}

.schedule-date-value {
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.schedule-water-usage-container {
  padding-top: 15px;
}

.schedule-email-modal {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.schedule-email-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
}

.schedule-type {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.schedule-share-types {
  display: flex;
}

.schedule-modal-header {
  padding-bottom: 15px;
  font-size: 20px;
}

.schedule-water-usage-button-container {
  display: flex;
  justify-content: space-around;
}

.email-error-message {
  color: red;
}

.email-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.repeat-end-form-item {
  & .help-button {
    margin-left: 10px;
  }
}

@primary-color: #3c61b4;