.notification-dropdown {
    float: right;
    height: 50px;
    line-height: 0px;
}

.notification-dropdown > div > div:nth-child(2) {
    right: -13px !important;
    top: 50px !important;
}

.raf-icon-badge {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.raf-icon-badge svg {
    fill: #1890ff;
}

.raf-icon-badge:hover svg {
    fill: #40a9ff;
}

.raf-icon-badge__badge {
    position: absolute;
    top: 8px;
    left: 50%;
}

.raf-icon-badge__badge p {
    margin: 6px 0 0 0;
}

.notification-dropdown-header {
    height: 50px;
    padding-left: 20px;
    padding-right: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.notification-dropdown-header .items-left {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: center;
}

.notification-dropdown-header h4 {
    font-size: 20px;
    margin: auto;
    margin-right: 20px;
}

.notification-dropdown-header .ant-btn {
    padding: 10px;
    display: flex;
    align-items: center;
}

.notification-dropdown-header .ant-btn svg {
    margin: 0px;
}

@media only screen and (max-width: 500px) {
    .notification-dropdown > div > div:nth-child(2) {
        width: 100vw !important;
    }

    .raf-dropdown-panel__content {
        max-height: calc(100vh - 150px);
    }
}
