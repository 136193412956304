.mobile-list-item {
  border: 1px solid #ccc;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;
}

.mobile-list-item .ant-card-body {
  padding: 15px;
}

.control-header {
  padding: 8px 0 0 8px;
}

.title-and-alarm-container,
.pump-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.title-and-alarm-container h4 {
  margin-bottom: 0;
  flex: 1 1;
}

.valve-button {
  padding-top: 30px;
}

.ant-tooltip-arrow {
  display: none;
}

.card-layout-style {
  width: 100%;
}
