.graph-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.multi-graph-page {
    overflow: auto;
}

.graph-page .page-body {
    display: flex;
    flex-direction: column;
}

.add-graph {
    border-color: blue;
    border-radius: 2px;
    background-color: #cccccc;
    padding: 5px;
}

.add-graph .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0px;
}

.graph-list > * {
    margin: 10px 0px;
}

.graph-list :first-child.chart-container {
    margin: 0px 0px;
}

.graph-list {
    padding-top: 1px;
    overflow: auto;
}

.chart-container {
    flex: 1;
}

.flex-input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-input-group > *:not(:first-child) {
    margin-left: 10px;
}

/* SensorDataChart styles */
.chart-container {
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.chart-header-container {
    background-color: #eee;
    height: 40px;
}

.chart-header-form {
    display: flex;
    padding: 5px 0px;
    
}

.chart-header-form > * {
    margin: 0px 5px;
}

.chart-header-form > .chart-variables {
    flex: 1;
    display: flex;
}

.chart-body {
    background-color: #fff;
    height: 200px;
}

 .chart-sm .chart-body {
    height: 100px;
}

.chart-md .chart-body {
    height: 200px;
}

.chart-lg .chart-body {
    height: 400px;
}

.graph-page .ant-page-header-heading {
    padding: 0px 20px;
    margin-bottom: -10px;
}

.graph-row-gap {
    row-gap: 10px;
    position: relative;
}

.lower-band-line,
.upper-band-line {
    opacity: 1 !important;
}

.highcharts-plot-band {
    opacity: 0.1;
}
@primary-color: #3c61b4;