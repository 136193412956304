.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .scheduler-container {
    height: 100%;
    width: 100%;
  }

  .dhx_scale_hour {
    line-height: 15px;
    border-bottom: none;
  }

  .dhx_scale_holder {
    background-image: none;
  }

  .dhx_scale_holder_now {
    background-image: none;
  }

  .dhx_cal_tab {
    border: none;
    color: none ;
  }

  .dhx_cal_navline.dhx_cal_navline_flex {
    padding: 0px;
  }

  .dhx_cal_navline.dhx_cal_navline_flex {
    padding: 0px;
  }

  .dhx_cal_prev_button {
    display: none;
  }

  .dhx_cal_today_button {
    display: none;
  }

  .dhx_cal_next_button {
    display: none;
  }

  .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab {
    box-shadow: none;
    display: contents;
  }
  .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab> text {
    display: flex;
    margin-left: 10px;
    margin-right: 5px;
  }

  .dhx_cal_navline.dhx_cal_navline_flex div {
    position: absolute;
  }

  .dhx_cal_prev_button{right: auto !important; left: 16px !important;}
  .dhx_cal_next_button{right: auto !important; left: 148px !important;}
  .dhx_cal_today_button{right: auto !important; left: 57px !important;}
  .dhx_cal_tab[name="month_tab"]{left: auto !important; right: 16px !important; border-radius: 20px}
  .dhx_cal_tab[name="day_tab"]{left: auto !important; right: 126px !important;border-radius: 20px}
  .dhx_cal_tab[name="week_tab"]{left: auto !important; right: 76px !important;border-radius: 20px}
  .dhx_cal_container_rtl  .dhx_cal_tab {
      border-right-style: solid;
      border-right-width: 1px;
  }

  .scheduler-outer {
      flex: 1;
      height: 100%;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
  }
    
  .scheduler-inner {
      height: 100%;
  }

  .dhx_minical_icon {
    left: auto !important;
    top: 4px!important;
    right: 66%;
  }

  .dhx_cal_prev_button {
    display: block;
    float: right !important;
    margin-left: 0px !important;
  }

  .dhx_cal_next_button {
    display: block;
    margin-left: 70px !important;
  }

  .dhx_cal_navline.dhx_cal_navline_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1vw;
    box-sizing: border-box;
    justify-content: flex-end;
}

.dhx_cal_navline.dhx_cal_navline_flex .selected {
  border-radius: 3px;
  padding-left: 2%;
  padding-right: 2%;
  background: #1890ff;
  border: 1px solid #1890ff;
  color: white;
}

.my_event {
  overflow: hidden;
  border-radius: 5px;
}

.dhx_cal_event .dhx_title {
  padding: 0px;
}

.dhx_scheduler_month .dhx_marked_timespan {
  display: block;
}

.dhx_scheduler_month .dhx_now_time {
  border-bottom: 0;
  border-left: 2px transparent;
}

.dhx_cal_navline div.dhx_minical_icon{
  background: url(../../../assets/images/calender.png) no-repeat center;
}

.dhx_cal_event_line {
  white-space: nowrap;
  padding: 0 0 0 0px;
}

.event_text {
  padding-left: 8px;
}

.dhx_matrix_line .dhx_cal_event_line {
  justify-content: unset !important;
}

/* For scheduleForm */
  
.left-half {
  flex: 1;
}

.right-half {
  flex: 0;
}

.form-container .ant-btn {
  margin-right: 8px !important;
}

.update-form-buttons {
  display: flex;
  justify-content: space-between;
}

.error-text{
  color: red;
  font-size: 14px;
  margin-top: 20px;
  display: block;
  text-align: center;
}

/* For IrrigationSchedule */
.chart-container-div {
  height: 100%;
  width: 100%;
  position: relative;
}

.modify-schedule-buttons .ant-btn-dangerous.ant-btn-primary {
  background: var(--dangerous-button-background) !important;
  border-color: var(--dangerous-button-background) !important;
}

.modify-schedule-buttons .ant-btn-primary[disabled] {
  background: var(--disabled-button-background) !important;
  border-color:  var(--disabled-button-border) !important;
}