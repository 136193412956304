:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}

.scrollable-page {
    overflow-y: auto !important;
}

.event-list-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
}

.event-list-container .ant-list-item {
    background-color: white;
}

.event-list {
    flex: 1 1;
    max-width: 1600px;
}

.event-list-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -10px;
    position: relative;
}

.event-list-filters > div {
    margin-right: 10px;
    min-width: 120px;
    margin-top: 10px;
}

.event-list-filters > div:last-of-type {
    margin-right: 0px;
}

.event-description > span {
    margin-right: 10px;
}

.loading-container {
    width: 100%;
    height: 100%;
    text-align: center;
}

@media screen and (max-width: 576px) {
    .event-list-container .ant-list-vertical .ant-list-item {
        flex-wrap: wrap;
    }

    .event-list {
        margin-top: 10px;
    }

    .event-list-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0px 10px;
        position: relative;
    }
    
    .filters-header {
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        align-items: flex-start;
        display: flex;
        margin-top: 5px;
    }

    .events-filter-details {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .events-filter-details div {
        display: inline;
        flex-grow: 1;
    }
    
    .events-filter-details .list-header {
        font-weight: bold;
        font-size: medium;
        margin-left: 4px;
    }
    
    .events-filter-details .list-header-value {
        font-size: medium;
    }

    .event-list-item-full-width {
        margin-top: 10px;
        width: 100%;
    }

    .bottom-sheet-header-container {
        display: flex;
        align-items: center;
    }

    .bottom-sheet-header {
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;
    }
    
    .event-filter-icon {
        margin-bottom: -4px;
        margin-left: -12px;
        width: 26px;
        height: 17px;
    }

    .event-header {
        margin-left: 3px !important;
    }

    .ant-list-items {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .event-page-datepicker.ant-picker-dropdown-range {
          top: calc(100vh - 580px) !important;
          left: -5px !important;
        }

    }
