.share-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px !important;
}

.share-button-title {
    padding: 0px 5px !important;
    height: 25px;
    margin-left: 5px;
}

.share-button-title svg {
    margin-right: 3px;
}

@primary-color: #3c61b4;