@import '~antd/dist/antd.css';

.nav-menu a {
    color: rgb(35, 35, 36);
}

.ant-menu-inline-collapsed span.menu-title {
  display: none;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0px 18px !important;
}

.ant-menu-inline-collapsed {
  width: 50px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(50,52,54,0.5);
}

.ant-menu-inline-collapsed {
  border-right: 1px solid #ddd;
}

.layout .scroll-button {
  display: none;
  padding: 0 0px;
}

.layout .scroll-button span {
  font-size: 20px;
  font-weight: 600;
  color: rgb(56 56 56 / 53%);
}

.layout .scroll-button span svg path {
  stroke-width: 4em;
  stroke: rgb(56 56 56 / 53%);
}

.layout .middle-menu {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #ddd;
  line-height: 46px;
  flex: 1 1 100%;
}

span.menu-icon {
  display: none;
}

.layout .middle-menu ul > li {
  margin: 0 10px !important; 
}

.nav-menu {
  display: contents;
}
.nav-menu .nav-menu-content {
  overflow-y: overlay;
  overflow-x: hidden;
}

.nav-menu-search-box {
  padding: 10px;
}

.ant-collapse-icon-position-right > .ant-collapse-item:first-child > .ant-collapse-header {
  padding: 8px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 16px);
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 5px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 3px 0px 35px;
}

.ant-list-item {
  padding: 8px;
}

.ant-carousel .slick-arrow::before {
  font-size: 24px;
  font-family: 'slick';
  color: #000;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 48%;
  font-size: 16px;
}

/* make scrollbar transparent */
.nav-menu-content::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 5%;
}

.ant-tooltip {
  z-index: 11122;
  position: relative;
  max-width: fit-content;
}

.ant-layout-sider-collapsed {
  flex: 0 0 0px !important;
  min-width: 0px !important;
}

.ant-layout-sider-collapsed .nav-menu-search-box {
  display: none;
}

.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  border-bottom: 1px solid #ddd;
  position: absolute;
}

.ant-layout-sider:not(.ant-layout-sider-trigger) {
  z-index: 11;
}


@media only screen and (max-width: 600px) {
  span.menu-title {
    display: none;
  }

  span.menu-icon {
    display: block;
    font-size: 18px;
    width: 18px;
  }
  
  .layout .middle-menu {
    width: 58%;
    overflow-x: auto;
    line-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
  }

  .layout .middle-menu ul {
    align-self: end;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item:first-child > .ant-collapse-header {
    padding: 8px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 16px);
  }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

  .layout .scroll-button {
    display: block;
    color: rgb(0 0 0);
    background: #ffffff; 
    border-color: #ffffff;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: -10px;
    margin-right: -10px;
    z-index: 11;
  }

  .layout .middle-menu {
    padding-left: 41px !important;
  }

  .layout .middle-menu ul {
    align-self: end;
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .layout .middle-menu {
      padding-left: 0px !important;
    }

    .layout .scroll-button {
      display: block;
      color: rgb(0 0 0);
      background: #ffffff; 
      border-color: #ffffff;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-left: -5px;
      margin-right: -5px;
    }

    .layout .scroll-button span {
      float: right;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 


    .layout .middle-menu {
      padding-left: 0px !important;
    }

    .layout .scroll-button span {
      float: right;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}