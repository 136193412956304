.block-irrigation-settings {
  & .page-header {
    margin-left: 0;
  }

  .render-ranch-data {
    background: #E6E6E6;
  }

  .ranch-name {
    background: #3158AB;
    color: white;
    padding: 10px;
    display: block;
    font-weight: bold;
    font-size: 18px;
  }

  .render-block-settings,
  .auto-schedule-buttons-wrapper {
    display: grid;
    grid-template-columns: 110px auto;
  }
}
@primary-color: #3c61b4;