.settings-title {
  display: flex;
  align-items: center;
}

.preference-icon {
  margin-right: 20px;
  font-size: 40px;
}

.settings-items {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 10px;
}

.settings-items a {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: black;
  height: 50px;
  padding-left: 15px;
  padding-right: 10px;
}

.settings-items .ant-typography {
  margin: 0px;
  margin-left: 20px;
  font-weight: normal;
}

.settings-items .icon-right {
  margin-left: auto;
  order: 2;
  color: #aaa;
}

.notifications-content {
  margin-top: 20px;
}

.checkbox-group label.ant-checkbox-wrapper {
  margin: 5px 10px;
  font-size: 20px;
}

.checkbox-group {
  margin-bottom: 30px;
}

.checkbox-group .ant-checkbox-inner, .ant-checkbox-input {
  transform: scale(1.5);
}

.notification-cb {
  display: flex;
  align-items: center;
}

.notification-cb .ant-typography {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 20px;
}

.units-container {
  padding: 20px;
}

.unit-item-container {
  display: flex;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.unit-item-container .ant-radio-group-outline {
  display: grid;
  grid-template-columns: 120px 120px;
}

.unit-item-container .ant-typography {
  width: 110px;
}

.units-gray-bg {
  background: #E6E6E6;
  padding: 20px;
}

.unit-item-container .ant-radio-wrapper {
  color: #666666;
  margin: 0;
  margin-bottom: 5px;
  white-space: break-spaces;
}

.imperial-metric-buttons-wrapper {
  margin: 20px 0;
}

.imperial-metric-buttons-wrapper .primary-button {
  color: white;
  margin-right: 15px;
}

.system-options {
  padding-left: 20px;
}

.language-settings,
.appearance-settings {
  margin-top: 30px;
}

.language-settings-options,
.appearance-settings-options {
  margin-left: 20px;
  margin-top: 10px;
}

.unit-item-divider {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

@media (max-width: 350px) {
  .unit-item-container .ant-radio-group-outline {
    grid-template-columns: auto;
  }
}

@media (min-width: 350px) and (max-width: 399px) {
  .units-container, .units-gray-bg {
    padding: 10px;
  }

  .unit-item-container .ant-radio-group-outline {
    grid-template-columns: 110px 110px;
  }
}

@media (min-width: 440px) {
  .unit-item-container .ant-radio-group-outline {
    grid-template-columns: 140px 140px;
  }
}
