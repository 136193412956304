.control-container,
.block-details-container,
.pressure-sensors-container {
  padding: 10px;
}

.pump-status-container {
  padding-top: 10px;
  align-items: center;
}

.pump-status-container .pump-state-text {
  margin: 0;
}

.pump-state-text span {
  font-weight: bold;
  text-transform: uppercase;
}

.pump-on-state {
  color: #3c62b3;
}

/* control tabs css */
.control-tab-container,
.div-select-container {
  box-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
  border-radius: 5px;
}

.control-tab-container .ant-radio-group {
  margin: 0;
}

.control-container span.ant-radio-button.ant-radio-button-checked {
  color: white;
}

.control-container label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.control-container label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  background: #3b61b3;
}

.control-container label.ant-radio-button-wrapper {
  margin: 0;
  color: #3b61b3;
  border: none;
}

.control-container .ant-radio-button-wrapper::before {
  content: initial;
}

.control-tab-container label.ant-radio-button-wrapper span {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: initial;
}

.block-name {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.block-name .ant-typography {
  margin: 10px;
}

.status-container {
  margin: 10px 0;
}

.status-container span {
  text-align: right;
  width: 100%;
  display: inline-block;
}

.block-details-additional-info {
  background: white;
  padding: 10px;
  margin-top: 10px;
}

.block-details-additional-info .mobile-list-item {
  margin-bottom: 30px !important;
  box-shadow: none;
  border: none;
}

.block-details-additional-info .mobile-list-item .ant-card-body {
  padding: 0;
}

.pump-specs-container {
  background: #e6e6e7;
  padding: 20px;
  border: 1px solid #cfcfcf;
  margin-top: 20px;
}

.pump-single-spec {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcfcf;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.pump-single-spec:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.pressure-summary-details {
  display: flex;
  justify-content: space-between;
}

.block-details-container .pressure-summary-container {
  margin-top: 30px;
}

.water-information-single {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.water-information-single:last-child {
  margin-bottom: 0;
}

.water-information-single .ant-typography {
  margin: 0;
}

.water-information-value {
  display: flex;
}

.water-information-value .ant-tag {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.water-information-single i.icon {
  font-size: 20px;
  color: white;
}

.water-information-label {
  min-width: 100px;
}

.pressure-canvas-container {
  position: relative;
  height: 100px;
  width: 100px;
}

.pressure-canvas-container canvas {
  position: absolute;
  left: -40px;
  top: -55px;
  z-index: 1;
}

.pressure-canvas-container::after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid darkgray;
  position: absolute;
  top: 58px;
  left: 45px;
}

#block-name-div {
  height: 57px;
  display: block;
  padding-top: 5px;
}

.pressure-canvas-container-valve {
  position: relative;
  width: 75px;
  height: auto;
  margin-top: 3px;
  margin-left: -70px;
}

.pressure-canvas-container-valve canvas {
  position: absolute;
  left: -5px;
  top: -26px;
  z-index: 1;
}

.pressure-canvas-container-valve::after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 1px solid darkgray;
  position: absolute;
  top: 18px;
  left: 27px;
}

.blocks-list-right {
  float: right;
  display: inline-flex;
  padding-right: 10px;
}

.blocks-list-left {
  float: left;
  width: 50%;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 600;
}

.override-container {
  text-align: center;
  font-size: 18px;
}

.override-schedule {
  margin: 20px 0;
  text-align: left;
}

.text-link {
  color: #2274c2;
}

.valve-content-divider {
  border-top: 2px solid #b8b8b8;
  margin: 20px 0;
}

.custom-button {
  padding: 10px 2px !important;
}

.pressure-sensor-name .icon {
  color: #626365;
  margin-right: 10px;
}

.back-button {
  z-index: 2;
}

.ant-badge-count {
  z-index: 1001;
}

.no-config {
  padding: 3px;
  font-weight: bold;
  text-align: center;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

.pressure-summary-value {
  padding-left: 8%;
  width: 40%;
}

.pressure-summary-container {
  position: relative;
}

.pressure-loader {
  position: absolute;
  top: 5%;
  left: 55%;
}

.pressure-container {
  position: relative;
}

.pump-valve-loader {
  position: absolute;
  top: 42%;
  left: 55%;
}

.display-pressure-value {
  padding-top: 8px;
}

.multiple-blocks-bgcolor:nth-child(odd) {
  background-color: #F4F3F5;
}

.valve-blockname {
  font-size: 18px;
}

/* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .pressure-loader {
    left: 65%;
  }

}

/* ----------- iPhone 6, 6S, 7 and 8 -----------*/
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .text-ellipsis {
    max-width: 255px;
  }

  .pressure-loader {
    left: 55%;
  }

  .pressure-summary-value {
    padding-left: 4%;
    width: 48%;
  }

}

/* ----------- iPhone 6+, 7+ and 8+ -----------*/
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .text-ellipsis {
    max-width: 180px;
  }

  .pressure-loader {
    top: 4%;
    left: 55%;
  }

}
