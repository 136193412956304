.mobile-account-settings-container, .mobile-account-settings-input {
  padding: 15px;
}

.mobile-account-change-password .mobile-account-settings-input {
  padding: 10px;
}

.mobile-account-settings-input {
  margin: 0;
}

.mobile-account-settings-input .ant-input-affix-wrapper>input.ant-input {
  margin: 0;
  border: none;
}

.mobile-account-settings-input input {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
}

.mobile-account-settings-input .ant-input-borderless,
.mobile-account-settings-input .ant-input-borderless:hover,
.mobile-account-settings-input .ant-input-password,
.mobile-account-settings-input input,
.mobile-account-settings-input input:focus,
.mobile-account-settings-input .ant-input-affix-wrapper.ant-input-password:focus,
.mobile-account-settings-input .ant-input-affix-wrapper-focused .mobile-account-settings-input input:hover,
.ant-form-item input.ant-input.mobile-account-email {
  border: none;
  border-bottom: 1px solid lightgray;
  border-radius: 0;
  padding-left: 0;
  box-shadow: none;
}

.mobile-account-settings-input .ant-form-item-explain {
  text-align: left;
  min-height: auto;
}

.mobile-account-settings-alert {
  margin-top: 20px;
}

.formatted-number,
.input-count {
  display: block;
}

.formatted-number {
  margin-left: 15px;
}

.input-count {
  text-align: right;
  margin-right: 15px;
}

.notification-wrapper {
  padding: 15px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.notification-text {
  margin-left: 10px;
}

.mobile-change-password {
  text-align: center;
  padding: 20px;
}

.mobile-change-password-button {
  font-size: 20px;
  font-weight: bold;
}
