.map-sensor-detail {
    margin-top: 20px;

    .ant-typography.value-text {
        font-size: 44px;
    }

    .ant-typography.unit-text {
        font-size: 20px;
    }

    .ant-typography.info-text {
        font-size: 14px;
    }
}

.bottomsheet-block {
    .sensor-detail-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ant-card,
    .mobile-list-item {
        border: none;
        box-shadow: none;
    }

    .ant-card-body,
    .mobile-list-item {
        padding: 0px !important;
        border: none;
    }

    .icontitle-container,
    .list-item-header,
    .title-and-alarm-container {
        display: none;
    }

    .ant-typography.value-text {
        font-size: 32px;
    }

    .ant-typography.unit-text {
        font-size: 20px;
    }

    .ant-typography.info-text {
        font-size: 14px;
    }

    .soil-moisture-chart-container {
        margin-top: 20px;
    }
}

.desktop-sidebar-details {
    padding: 0px !important;
    margin-top: -20px !important;
}
@primary-color: #3c61b4;