.detail-header {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.detail-header .ant-typography {
  margin: 0px;
  font-size: 20px;
}

.detail-body .ant-typography {
  font-size: 20px;
  margin: 0px;
  line-height: 40px;
}

.detail-body {
  margin-top: 10px;
}

.schedule-header-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 75px);
  margin-top: 12px;
}