/* General CSS */
.primary-color,
.primary-color.ant-typography {
  color: #3b61b3;
}

.success-color,
.success-color.ant-typography {
  color: #429246;
}

.danger-color,
.danger-color.ant-typography {
  color: #c12c2d;
}

.primary-background-color,
.degree::before {
  background-color: #3b61b3;
}

.flex-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

/* IconTitle CSS */
.icontitle-container {
  display: flex;
  align-items: center;
}

.icontitle-container .ant-typography {
  color: #4e4e4e;
  margin-bottom: 0px;
}

/* ValueUnitInfo CSS */
.icontitle-container .ant-typography .icon,
.icontitle-container .ant-typography svg {
  color: black;
  margin-right: 10px;
}

.value-container .value-text {
  margin-right: 5px;
  line-height: 26px;
  font-size: 30px;
}

.unit-text.ant-typography {
  font-size: 14px;
  line-height: 12px;
}

.info-text {
  font-size: 10px;
  font-style: italic;
}

.value-unit-info .ant-typography {
  margin-bottom: 0;
}

.degree {
  position: relative;
  margin-left: 4px;
}

.degree::before,
.fh-temperature .degree::after,
.degree::before {
  content: '';
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 10px;
  position: absolute;
  left: -5px;
  top: 2px;
}

/* Card CSS */
.card-footer-txt {
  line-height: 10px;
  color: #55a0d6;
  font-size: 10px;
}

.separator {
  margin: 0 5px;
  font-size: 17px;
  line-height: 20px;
  font-style: italic;
}

/* SoilRefChart CSS */
.soil-ref-chart-wrapper {
  min-width: 150px;
}

.min-height-150 {
  min-height: 150px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  height: 100%;
  width: 100%;
}

.plant-sensor-chart-container {
  margin-left: -15px;
  margin-top: 20px;
}

.list-control-pump-detail-item .mobile-list-item {
  overflow: initial;
  height: auto !important;
}

.list-control-pump-detail-item.block-details-additional-info {
  padding: 10px 15px 15px;
}

.weather-info-container {
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid #ececec;
}

.weather-info i,
.weather-info svg {
  margin-top: 4px;
  margin-right: 10px;
  fill: #3b61b3;
}

.weather-info-container>div:first-child {
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.min-width-60 {
  min-width: 60px;
}

.min-width-80 {
  min-width: 80px;
}

.fh-cloud-icon {
  min-width: 30px;
  color: #e9da48;
}

.forecast-history-list-item {
  padding: 10px;
  border-top: 1px solid #ececec;
}

.max-temp {
  font-size: 17px;
  margin-right: 5px;
}

.fh-temperature {
  position: relative;
}

.fh-temperature .deg::after {
  position: absolute;
  content: 'o';
  top: -4px;
  font-size: 10px;
}

.max-temp::after {
  font-weight: bold;
}

.weather-list-table .ant-table {
  margin-top: 10px;
}

.weather-list-table .ant-table-fixed {
  table-layout: fixed;
}

.weather-list-table .ant-table-tbody>tr>td,
.weather-list-table .ant-table-thead>tr>th {
  word-wrap: break-word;
  word-break: break-all;
  padding: 10px 5px;
}

.weather-list-table .ant-table-thead .ant-typography {
  margin: 0;
}

.mobile-radio-toggle, .div-select-container {
  box-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
  border-radius: 5px;
}

.mobile-radio-toggle .ant-radio-group {
  margin: 0;
}

.mobile-radio-toggle span.ant-radio-button.ant-radio-button-checked {
  color: white;
}

.mobile-radio-toggle label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.mobile-radio-toggle label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  background: #3b61b3;
}

.mobile-radio-toggle label.ant-radio-button-wrapper {
  margin: 0;
  color: #3b61b3;
  border: none;
}

.mobile-radio-toggle .ant-radio-button-wrapper::before {
  content: initial;
}

.mobile-radio-toggle label.ant-radio-button-wrapper span {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: initial;
}

@media only screen and (max-width: 350px) {

  .weather-list-table .ant-table-tbody>tr>td,
  .weather-list-table .ant-table-thead>tr>th {
    padding: 10px 0;
  }
}

.desktop-container {
  display: flex;
  grid-row-gap: 15px;
  row-gap: 15px;
  flex-direction: column;
  width: 100%;
}


.sensor-summary-list-container,
.list-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.desktop-container {
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.desktop-container .page-header {
  margin-left: 0;
}

.desktop-container .sensor-list-inner .list-container {
  padding-top: 0;
}

.desktop-container .mobile-list-item,
.sensor-summary-list-container .mobile-list-item {
  height: 100%;
}

.soil-sensors-wrapper .desktop-container .ant-radio-group {
  display: flex;
  width: 100%;
}

.soil-sensors-wrapper .desktop-container .ant-radio-group .ant-radio-button-wrapper {
  flex: 1
}

.pump-list-container .desktop-container .block-details-additional-info {
  margin-top: 0;
}

@media only screen and (min-width: 900px) {
  .desktop-container .sensor-detail {
    box-shadow: -3px 3px 19px rgb(0 0 0 / 40%);
    padding: 15px 30px 30px;
  }
}
