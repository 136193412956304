.etc-percent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .etc-percent {
        font-size: 32px;
        font-weight: 600;
    }

    .etc-description {
        font-size: 16px;
        font-weight: 400;
    }
}

@primary-color: #3c61b4;