@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'),
       url(./assets/fonts/proximanova-regular-webfont.woff2) format('woff2'),
       url(./assets/fonts/proximanova-regular-webfont.woff) format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'),
       url(./assets/fonts/proximanova-bold-webfont.woff2) format('woff2'),
       url(./assets/fonts/proximanova-bold-webfont.woff) format('woff');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: "Proxima Nova", "Open Sans", "Gill Sans MT", "Gill Sans",
    Corbel, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
