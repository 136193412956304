.schedule-options-modal .ant-modal-footer {
    row-gap: 10px;
    flex-direction: column;
    display: flex;
}

.schedule-options-modal .ant-modal-body {
    display: none;
}

.schedule-options-modal .ant-modal-footer button + button {
    margin: 0px;
}

.schedule-options-modal .ant-modal-footer .ant-btn {
    height: 40px;
}

.ant-modal-title {
    font-weight: bold;
}

@primary-color: #3c61b4;