@import '../app/colors';

@select-border-radius: 2px;
@select-height: 40px;

.status-buttons {
  display: flex;
  justify-content: center;
}

.status-buttons .button {
  padding: 10px 15px;
  color: #808080;
  background: #e6e6e6;
  margin-right: 2px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  flex: 1;
  width: initial;
  height: initial;
}

.status-buttons .button.selected {
  color: white;
  background: @primary;
}

.button.left-border {
  border-radius: 30px 0px 0px 30px;
}

.button.right-border {
  border-radius: 0px 30px 30px 0px;
  margin-right: 0;
}

.primary-button,
.primary-button:hover,
.primary-button:focus {
  background-color: @primary;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.white-bg {
  background: white;
}

.override-modal button.ant-modal-close {
  background: black;
  color: white;
  border: 3px solid white;
  border-radius: 100%;
  right: -6px;
  top: -14px;
  width: 40px;
  height: 40px;
}

.override-modal svg {
  top: 50px;
}

.override-modal span.ant-modal-close-x {
  line-height: initial;
  height: auto;
  width: auto;
  font-size: 17px;
}

.override-modal .ant-modal-footer {
  border: none;
  text-align: center;
}

.override-modal-btn.ant-btn {
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  height: auto;
  font-size: 15px;
}

.override-modal-confirm:active,
.override-modal-confirm:hover,
.override-modal-confirm:focus,
.override-modal-confirm {
  background: #3b61b3;
}

.layout-mobile {
  .page-content {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
  }

  .page-content .mobile-list-item {
    margin: 0px;
  }

  .page-content .page-header {
    margin-bottom: -5px;
  }

  .item-list {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
  }

  .div-select-container .ant-select-selector {
    height: auto;
  }

  .ranch-block-select-container .ant-select-selector {
    height: 40px;
  }

  .ranch-block-select-container,
  .ranch-block-select-container .ant-select {
    background-color: white;
    border-radius: 5px;
  }

  .div-select-container .ant-select-selection-item {
    font-size: 20px;
    line-height: 20px;
    margin: auto;
  }

  .page-tab-container .ant-radio-group {
    margin: 0px;
  }

  .page-tab-container .ant-radio-button-wrapper {
    margin: 0px;
  }

  .list-container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .list-container .mobile-list-item {
    margin: 0px;
  }

  .ant-switch {
    height: 30px;
    width: 80px;
  }

  .ant-switch-inner {
    font-size: 16px;
  }

  .ant-switch-handle {
    height: 26px;
    width: 26px;
  }

  .ant-switch-checked {
    background-color: @primary;
  }

  .ant-switch-checked .ant-switch-inner {
    margin: 0 26px 0 7px !important;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 26px - 2px);
  }

  .ant-switch-handle::before {
    border-radius: 15px;
  }

  .block-select-container .ant-select {
    border: 1px solid @select-border-color;
    border-radius: @select-border-radius;
    min-height: @select-height;
    display: flex;
    flex-direction: column;
  }

  .block-select-container .ant-select-selector {
    flex-grow: 1;
  }

  .block-select-container .ant-tag {
    border-radius: 15px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 0px 8px 0px 12px;
  }

  .block-select-container .ant-tag-close-icon {
    margin-left: 5px
  }

  .block-select-container .ant-tag svg {
    font-size: 14px;
  }

  .date-time-picker .ant-picker {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
  }

  .date-time-picker .time-picker-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
    width: 100px;
  }

  .ant-picker,
  .ant-select,
  .ant-select-selector,
  .time-picker-btn,
  .ant-input-affix-wrapper,
  .ant-input {
    background-color: rgb(248, 248, 248);
  }

  .ant-form-item-label>label {
    font-weight: 600;
    font-size: 16px;
  }

  .ant-radio-button-wrapper {
    font-size: 16px;
  }

  .ant-radio-button-wrapper-checked {
    background-color: @primary !important;
    border-color: @primary !important;
  }
}

.inline-page-loader-div {
  display: flex;
  align-items: center;
}

.inline-page-loader {
  font-size: large;
}

.header-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 103px);
  margin-top: 12px;
}

/* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
 @media only screen
 and (min-device-width: 320px)
 and (max-device-width: 568px)
 and (-webkit-min-device-pixel-ratio: 2)
 and (orientation: portrait) {

  .header-ellipsis {
    max-width: calc(100vw - 91px);
  }

}

/* ----------- iPhone 6, 6S, 7 and 8 -----------*/
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait){

  .header-ellipsis {
    max-width: calc(100vw - 111px);
  }

}

/* ----------- iPhone 6+, 7+ and 8+ -----------*/
   @media only screen
   and (min-device-width: 414px)
   and (max-device-width: 736px)
   and (-webkit-min-device-pixel-ratio: 2)
   and (orientation: portrait){

  .header-ellipsis {
    max-width: calc(100vw - 117px);
  }

}

/* ----------- Desktop -----------*/
@media (min-width: 900px) {
  .page-content {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    margin: 15px;
  }

  .page-tab-container .ant-radio-button-wrapper {
    margin: 0px;
  }

}

.ranch-block-select-container,
.ranch-block-select-container .ant-select {
  background-color: white;
}

@primary-color: #3c61b4;