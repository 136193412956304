div.map-mobile {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
}

div.map-mobile-container {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}

div.map-component-mobile {
  flex: 1;
}

div.controls-all-container {
  position: fixed;
  z-index: 2;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  padding: 0 8px;
}

div.controls-box {
  margin-top: 10px;
  display: flex;
  padding-left: 2px;
}

.controls-box .ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-radius: 5px;
}

div.controls-left {
  display: flex;
  height: 45px;
}

div.controls-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 10px;
}

div.bottomsheet-block {
  padding: 0 20px;
}

.bottom-sheet-header {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}

.bottom-sheet-header .ant-btn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-rsbs-header] {
  padding-top: 8px !important;
}

[data-rsbs-header]:before {
  top: 8px !important;
}

.bottomsheet-block .expand-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.text-imagery {
  display: flex;
  font-weight: bold;
  align-items: center;
  font-size: 20px;
  margin-top: -3px;
}

.layout-width {
  width: 100%;
}

.layout-height {
  background: none;
}

.mobile-header-color {
  background-color: #f0f2f5;
}

@media only screen and (min-device-width: 320px) 
and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) 
and (orientation: portrait) {

  .ant-picker-dropdown {
    position: relative;
    margin: 0px 8px;
  }

  .ant-picker-date-panel {
    width: 96vw !important;
  }

  .ant-picker-content {
    width: 95vw !important;
    margin: 0px -11px !important;
  }
}

.map-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px;
  column-gap: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  margin-top: 10px;
  font-size: 16px;
}

.map-overlay .degree {
  position: relative;
  margin-left: 4px;
}

.map-overlay .degree::after {
  content: '';
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 10px;
  position: absolute;
  right: -6px;
  top: 3px;
  background-color: white;
}

.map-overlay .degree::before {
  display: none;
}

.bottom-sheet-header-container {
  display: flex;
  align-items: center;
}

.block-map-detail,
.ranch-map-detail {
  font-size: 18px;
  row-gap: 10px;
}

div.help-icon {
  margin-top: 5px;
  margin-left: 10px;
}

.satellite-imagery-container-highlight {
  border: 1px solid #000000;
}

.satellite-imagery-date-highlight {
  display: flex;
  justify-content: center;
  background-color: #000000;
  color: #FFFFFF;
  width: 100%;
}

.anomaly-icon {
  float: right;
  margin-top: 4px;
  margin-left: 7px;
}

.satellite-imagery-toggle-switch {
  display: flex;
  width: calc(100vw - 15vw);
  margin-top: 5px;
  margin-left: -2px;
  padding-top: 5px;
}

@media only screen and (max-height: 650px) {

  .map-settings-modal .ant-modal-body {
    overflow-y: auto !important;
    height: calc(100vh - 40vh) !important;
  }

}

.map-desktop-container {
  position: absolute;
  bottom: 4px;
  width: 500px;
  z-index: 1;
  margin-right: 400px;
  min-width: calc(100% - 400px);
  display: flex;
  height: 100%;
}

.map-desktop-control-box {
  width: 25% !important;
  margin-top: 0px !important;
}

.map-desktop-sensor-header {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.drawer-desktop .ant-drawer-header {
  padding-left: 10px;
} 

.map-desktop-sensor-header-detail {
  display: flex;
  width: 100% !important;
  padding-left: 10px;
}