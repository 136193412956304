.control-container {
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
}

.single-column-page {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: visible;
}

.ranch {
  margin-bottom: 10px;
}

h3 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}

.inner-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.last-update {
  float: right;
  font-size: 12px;
  display: flex;
  font-weight: 500;
  margin-top: -12px;
}

.mobile-screen {
  margin-bottom: 0px;
  display: flex;
  align-items: baseline;
}

.mobile-screen div {
  margin-left: 1%;
  width: calc(100% - 90px);
}
/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

  h3 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }

  .inner-container .ant-collapse-content-box {
    font-size: 16px;
    padding: 2px;
  }

  .padded {
    padding: 10px 5px !important;
  }

  .last-update {
    float: right;
    font-size: 12px;
    display: flex;
    font-weight: 500;
    margin-top: 4px;
  }

  .ant-page-header {
    padding: 0px !important;
  }
  
  .ant-divider-horizontal {
    margin: 0px !important;
  }

  .inner-container {
    margin: 0px -3px;
  }

  .ant-table-content colgroup col {
    min-width: 100% !important;
  }

  .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding:5px !important;
    text-align: center;
  }

  .ant-table-row-expand-icon {
    margin-right: 5px;
    margin-left:3px;
    width:14px;
    height:14px;
    padding:7px;
  }

  .ant-table-row-expand-icon::before {
    top: 6.5px;
    right: 2.5px;
    left: 4px;
    height: 1px;
  }
 
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding-top:0px;
    padding-bottom:0px;
    padding-left:1px;
    text-align: center;
  }

  .ant-table .ant-table-small .ant-table-thead > tr > th:nth-child(2) {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 68px;
    min-width: 40px;
    overflow: hidden;
  }
   
  .ant-table .ant-table-small .ant-table-thead > tr > th:nth-child(4) {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 57px;
    min-width: 57px;
    overflow: hidden;
  }

  .ant-table .ant-table-small .ant-table-thead > tr > th.ant-table-cell:nth-child(1) {
    text-align: left;
  }

  .ant-table .ant-table-small .ant-table-tbody > tr > td.ant-table-cell:nth-child(1) {
    text-align: left;
    padding-left:4px;
  }

 }

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

  h3 {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
  }

  .inner-container .ant-collapse-content-box {
    font-size: 16px;
    padding: 2px;
  }

  .padded {
    padding: 10px 10px !important;
  }

  .inner-container {
    margin: 0px -7px;
  }

  .mobile-screen div {
    margin-left: 1%;
    width: calc(100% - 97px);
  }
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {

  h3 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
  }

  .inner-container .ant-collapse-content-box {
    font-size: 20px;
    padding: 2px;
  }

  .padded {
    padding: 10px 10px !important;
  }

  .mobile-screen div {
    margin-left: 1%;
    width: calc(100% - 99px);
  }
  
}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {

  .ant-collapse-content-box {
    font-size: 20px;
  }

  h3 {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
  }

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

/* large screens */
@media (min-width: 1200px) {

  .ranch {
    max-width: 500px;
  }
}


@media only screen and (max-width: 750px) {

  .padded {
    padding: 10px 10px;
  }

  .ant-table .ant-table-small .ant-table-thead > tr > th.ant-table-cell:nth-child(1) {
    width:100%;
  }
}