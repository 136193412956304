.schedule-form-container {
  height: 100vh;
  width: 100vw;
}

.form-container {
  padding: 10px;
  margin: auto;
  max-width: 400px;
}

#back-button-form {
  float: right;
}

.ant-form  .ant-picker-range {
  width: 100%;
}

.form-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.form-heading .ant-page-header-ghost {
  width: 100%;
  text-align: center;
  padding-left: 0px;
}

.form-heading .ant-page-header-heading-left {
  width: 100%;
}

.form-heading .ant-page-header-heading-title {
  width: 100%;
  text-align: center;
}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {

}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {

  .ant-page-header-heading-extra {
      clear: inherit;
      padding-top: 0px;
  }

  .form-heading .ant-page-header-heading-title {
    font-size: 16px;
  }

}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {
  
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) { 

  .ant-page-header-heading-extra {
      clear: inherit;
      padding-top: 0px;
  }

  .form-heading .ant-page-header-heading-title {
    font-size: 18px;
  }

}

/* Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) { 

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 

  .ant-page-header-heading-extra {
      clear: inherit;
      padding-top: 0px;
  }

  .form-container {
    padding: 3px;
  }

}

/* Landscape */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 

  .ant-page-header-heading-extra {
      clear: inherit;
      padding-top: 0px;
  }

}

/* Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 

}

/* ----------- Non-Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    .form-heading .ant-page-header-heading-title {
      padding-right: 75px;
    }

}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 

    .form-heading .ant-page-header-heading-title {
      padding-right: 75px;
    }

}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1800px) 
  and (max-device-width: 2000px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi)
  and (orientation: landscape) { 

    .form-heading .ant-page-header-heading-title {
      padding-right: 75px;
    }

}

@media (max-height: 1920px) and (min-width: 1600px) and (orientation: landscape) {

  .form-heading .ant-page-header-heading-title {
    padding-right: 75px;
  }

}

@media (min-height: 900px) and (max-width: 1440px) and (orientation: landscape) {

  .schedule-form-container {
    height: 92vh;
    width: 100vw;
  }
}

@media (min-width: 320px) and (max-width: 750px) {
  
  .back-form-text {
    display: none !important;
  }
}
