.block-recommendation-summary {
    display: flex;
    flex-direction: column;

    .ant-tag {
        height: 30px;
        font-size: 16px;
        border-radius: 5px;
        align-items: center;
        display: flex;
    }
}

@primary-color: #3c61b4;