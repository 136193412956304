.recommendation-action-container {
  overflow-y: auto;
  margin-bottom: 50px;
}

.recommendation-action-container-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 4px;
}

.recommendation-action-card-date {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.recommendation-action-card-duration {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.recommendation-action-card-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center,
}

.recommendation-action-card-repeat {
  margin-top: 20px;
}

.recommendation-action-card-link-container {
  margin-top: 15px;
}

.recommendation-action-card-link {
  padding: 20px;
  font-weight: bold;
}

.recommendation-action-card-btn-container {
  width: 100%;
}

.duration-slider-container {
  display: flex;
  min-height: 240px;
  padding: 20px;
}
